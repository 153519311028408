'use strict';

angular.module('mvpcarrefourApp')
    .factory('Vote', ($http, $q) => {
        return {
            myVotes: (userId, full) => {
                // return userId ? $http.get('/api/votes/' + userId + '/myvotes')
                //   .then(result => { return result.data;}) : [] ;
                var deferred = $q.defer();
                userId ? $http.get('/api/votes/' + userId + '/myvotes' + (full ? '?full' : '')).success(function(data) {
                    deferred.resolve(data);
                }) : [] ;

                return deferred.promise;
            },
            vote: (data) => {
                return $http.post('/api/votes/', data)
                    .then(result => {
                        return result.data; });
            },
            allVotes: () => {
                 return $http.get('/api/votes')
                    .then(result => {
                        return result.data; });
            },
            update: (id, data) => {
                return $http.patch('/api/votes/' + id, data)
                  .then(result => {
                    return result.data; });
            }
        }
    });
